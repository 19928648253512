<template>
  <section id="kits-header">
    <section class="pt-container">
      <div class="pt-header">
        <div class="pt-title">
          {{  title  }}
          <span id="help-icon" class="icon-box">
            <HelpCircle class="icon stroke" />
          </span>

          <b-tooltip target="help-icon" placement="bottom">
            {{ tooltip  }}
          </b-tooltip>
        </div>

        <div>
          <b-button class="no-print" variant="primary" @click="dispatchAction">
            {{ action }}
          </b-button>

          <b-button class="wh-button no-print" variant="outline-primary" @click="xlsx">
            Download xlsx
          </b-button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>

export default {
  name: 'generic-tab-header',
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
  },
  props: {
    title: { type: String, required: true },
    tooltip: { type: String, required: true },
    action: { type: String,  required: true },
  },
  methods: {
    dispatchAction () {
      this.$emit('action')
    },
    xlsx () {
      this.$emit('xlsx')
    }
  }
}
</script>

<style lang="scss">
#kits-header {
  .pt-container {
    padding: 30px 10px 0px 10px;
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .pt-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .placeholder-color::placeholder {
      color: var(--type-placeholder);
    }

    .wh-button {
      margin: 0 0 0 10px;
      border-color: var(--blue-500);
      color: var(--blue-500);
    }
    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }
    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: #525C7A;
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
  }
}
</style>